html {
  overflow: hidden;
  height: 100%;
}
body {
  overflow-y: auto;
  height: 100%;
  overscroll-behavior: none;
  scrollbar-gutter: stable;
}
.cls_moneyGuideImage {
  max-width: 100%;
}
.page-header {
  position: fixed;
  top: 20px;
  left: 30px;
  right: 30px;
}
.page {
  page-break-after: always;
}
.page-header-space {
  height: 100px;
}
.page-footer, .page-footer-space {
  height: 50px;
}
.cls_avoidBrk {
  page-break-inside: avoid;
}
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    overscroll-behavior: auto;
  }
  body {
    margin: 0;
  }
  thead {
    display: table-header-group;
  } 
  tfoot {
    display: table-footer-group;
  }
}
.react-grid-item.react-grid-placeholder {
  background: white !important;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 8px;
}
.react-grid-item.cssTransforms {
  transition-property: none  !important;
}
.cls_see-more-btn_EXPANDED , .cls_see-more-btn_ACCOUNTDETAILS {
  display: none !important;
}
.cls_hideSeeMore {
  opacity: 0 ;
}
@media only screen and (max-width: 900px) {
  .cls_moneyGuideImage {
    max-height: 187.5px;
    max-width: 262.5px;
  }
}

@media only screen and (max-width: 900px) {
  body {
    background-image: none !important;
  }
  .cls_see-more-btn_EXPANDED , .cls_see-more-btn_ACCOUNTDETAILS {
    display: block !important;
  }
}

#id_search:-webkit-autofill,
#id_search:-webkit-autofill:hover, 
#id_search:-webkit-autofill:focus, 
#id_search:-webkit-autofill:active{
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
}
.cls_errorCardImage {
  width: 100%; 
  height: 100%;
  border-radius: 8px;
}
.cls_recharts_bold {
  font-weight: 700 !important;
}

.cls_recharts_hide {
  opacity: 0.2;
}

.cls_closeSpan {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}