.loader {
    margin-bottom: 8px;
    width: 42px ;
    height: 62px;
    -webkit-animation: text-focus-in 1.5s cubic-bezier(.55,.085,.68,.53) both!important;
    -moz-animation: text-focus-in 1.5s cubic-bezier(.55,.085,.68,.53) both!important;
    -o-animation: text-focus-in 1.5s cubic-bezier(.55,.085,.68,.53) both!important;
    -ms-animation: text-focus-in 1.5s cubic-bezier(.55,.085,.68,.53) both!important;
    animation: text-focus-in 1.5s cubic-bezier(.55,.085,.68,.53) both!important;
}

.indicator-wrap {
    text-align: center;
    height: 5px;
    width: 100%;
}

.linear-progress-bar {
    display: block;
    height: 100%;
    background: linear-gradient(90deg,#778e9a,#fff,#778e9a);
    background-size: 300% 100%;
    animation: progress-animation 1s linear infinite;
}

@keyframes progress-animation {
    0% {
        background-position: 100%
    }

    100% {
        background-position: 0
    }
}